<template>
    <v-card class="upload-job-card">
        <!--<v-list v-if="!success">
            <v-list-item-group v-model="item">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-comment-flash</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content @click="quickResume">
                        <v-list-item-title>快捷创建简历</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-word</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content @click="uploadFile">
                        <v-list-item-title>
                            上传简历文件(支持word或pdf)
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-picture-in-picture-bottom-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content @click="uploadImg">
                        <v-list-item-title>上传简历图片(可上传多张jpg或png)</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>-->
        <h3 class="upload-job-title">表单填写</h3>
        <v-form ref="form" v-model="valid" lazy-validation v-if="!success">
            <v-text-field
                    v-model="record.name"
                    :rules="rules.name"
                    label="姓名"
                    hint="请输入姓名"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-account"
            ></v-text-field>
            <v-select
                    v-model="record.gender"
                    :rules="rules.gender"
                    :items="dict.gender"
                    label="性别"
                    hint="请选择性别"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-gender-male"
                    item-text="label"
                    item-value="value"
            >
            </v-select>
            <v-text-field
                    v-model="record.mobile"
                    :rules="rules.mobile"
                    label="手机"
                    hint="请输入移动电话"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-cellphone"
                    counter="11"
            ></v-text-field>
            <v-text-field
                    v-model="record.region"
                    :rules="rules.region"
                    label="籍贯"
                    hint="请选择籍贯"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-map-marker"
                    readonly
                    @click="showRegionDialog()"
            ></v-text-field>
            <v-menu ref="birthMenu" v-model="birthMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model="record.birthDate"
                            :rules="rules.birthDate"
                            label="出生日期"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            clear-icon="mdi-close-circle"
                            v-bind="attrs"
                            v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker type="month" v-model="record.birthDate" locale="zh-cn" no-title scrollable>
                    <v-btn text color="primary" @click="$refs.birthMenu.save(date)">
                        确认
                    </v-btn>
                </v-date-picker>
            </v-menu>
            <v-select
                    v-model="record.education"
                    :rules="rules.education"
                    :items="dict.education"
                    label="学历"
                    hint="请选择学历"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-school"
                    item-text="label"
                    item-value="value"
            >
            </v-select>
            <v-text-field
                    v-model="record.college"
                    :rules="rules.college"
                    label="毕业学校"
                    hint="请输入毕业学校"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-bus-school"
            ></v-text-field>
            <v-text-field
                    v-model="record.major"
                    :rules="rules.major"
                    label="专业"
                    hint="请输入专业"
                    clearable
                    clear-icon="mdi-close-circle"
                    prepend-icon="mdi-bus-school"
            ></v-text-field>

            <v-select
                v-model="record.jobId"
                :rules="rules.jobId"
                :items="jobList"
                label="发展意向"
                hint="请选择发展意向"
                persistent-hint
                clearable
                clear-icon="mdi-close-circle"
                prepend-icon="mdi-developer-board"
                item-text="name"
                item-value="jobId"
            >

            </v-select>

            <v-select
                v-model="record.jobSource"
                :rules="rules.jobSource"
                :items="dict.jobSource"
                label="渠道来源"
                hint="请选择渠道来源"
                persistent-hint
                clearable
                clear-icon="mdi-close-circle"
                prepend-icon="mdi-developer-board"
                item-text="label"
                item-value="value"
            >
            </v-select>

            <v-file-input
                    v-model="file"
                    placeholder="请上传您的简历(名字+发展方向)"
                    label="请上传您的简历(名字+发展方向)"
                    prepend-icon="mdi-paperclip"
            >
                <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>

            <!--
            <v-file-input
                    v-model="imgList"
                    multiple
                    placeholder="请上传您的简历(图片)"
                    label="请上传您的简历(图片)"
                    prepend-icon="mdi-paperclip"
            >
                <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>
            -->
        </v-form>
        <v-btn color="info" v-if="!success" :loading="loading" :disabled="!valid" block @click="addResume">提交</v-btn>
        <v-alert type="success" v-if="success">您的信息已提交！</v-alert>

        <region-modal ref="regionModal" @close="getRegionArea"/>

        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>
    </v-card>

</template>

<script>
    import RegionModal from "./RegionModal";
    export default {
        name: "uploadJobModal",
        components: {RegionModal},
        data(){
            return{
                item: null,
                success: false,
                valid: true,
                birthMenu: false,
                date: null,
                record: {
                    name: null,
                    gender: null,
                    mobile: null,
                    region: null,
                    birthDate: null,
                    education: null,
                    college: null,
                    major: null,
                    jobId: null,
                    jobSource: null,
                },
                rules: {
                    name: [
                        v => !!v || '姓名是必输项',
                    ],
                    gender: [
                        v => !!v || '性别是必选项',
                    ],
                    mobile: [
                        v => !!v || '手机号是必输项',
                        v => {
                            const reg=/^1[3456789]\d{9}$/;
                            if(v && !reg.test(v)){
                                return '请输入有效的手机号码';
                            }
                            return true;
                        }
                    ],
                    region: [
                        v => !!v || '籍贯是必输项',
                    ],
                    birthDate: [
                        v => !!v || '出生日期是必选项',
                    ],
                    education: [
                        v => !!v || '学历是必选项',
                    ],
                    college: [
                        v => !!v || '毕业学校是必输项',
                    ],
                    major: [
                        v => !!v || '专业是必输项',
                    ],
                    jobId: [
                        v => !!v || '发展方向是必选项',
                    ],
                    jobSource: [
                        v => !!v || '渠道来源是必选项',
                    ],
                },
                dict: {
                    gender: [
                        {value: '男', label: '男'},
                        {value: '女', label: '女'},
                    ],
                    education: [
                        {value: '本科', label: '本科'},
                        {value: '硕士', label: '硕士'},
                        {value: '博士', label: '博士'},
                    ],
                    intention: [
                        {id: '0', value: '市场', label: '市场'},
                        {id: '1', value: '战略投资', label: '战略投资'},
                        {id: '2', value: '政策研究', label: '政策研究'},
                        {id: '3', value: '监察审计', label: '监察审计'},
                        {id: '4', value: '高管助理', label: '高管助理'},
                        {id: '5', value: '人力相关', label: '人力相关'},
                        {id: '6', value: '财务相关', label: '财务相关'},
                        {id: '7', value: '法务相关', label: '法务相关'},
                    ],
                    jobSource: [
                        {value: '双选会', label: '双选会'},
                        {value: '宣讲会', label: '宣讲会'},
                        {value: '校园大使', label: '校园大使'},
                        {value: '网络', label: '网络'},
                        {value: '其他', label: '其他'},
                    ],
                },
                loading: false,
                snackbar: false,
                message: null,
                provinceList: [],
                jobList: [],
                file: null,
                imgList: [],
                applyId: null,
            }
        },
        methods:{
            showRegionDialog(){
                this.$refs.regionModal.provinceList = this.provinceList;
                this.$refs.regionModal.show();
            },
            getRegionArea(region){
                this.record.region = region.province + '-' + region.city;
            },
            getProvinceList(){
                this.$http.post('apply/getProvince').then(({data}) => {
                    this.provinceList = data.provinceList;
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            getJobList(){
                this.$http.post('job/jobList').then(({data}) => {
                    this.jobList = data.jobList;
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            addResume(){
                if (!this.$refs.form.validate()){
                    return;
                }
                //校验文件
                if (this.file){
                    const checkSuffix = this.file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        || this.file.type === 'application/pdf' || this.file.type === 'application/msword';
                    if (!checkSuffix){
                        this.snackbar = true;
                        this.message = '请上传word或pdf文件！';
                        return
                    }
                    const fileSize = this.file.size / 1024 / 1024;
                    if (fileSize > 8){
                        this.snackbar = true;
                        this.message = '上传文件不得大于8M';
                        return
                    }
                }
                //校验图片
                /*
                if (this.imgList.length > 0){
                    for(let i = 0; i < this.imgList.length; i++){
                        const checkSuffix = this.imgList[i].type === 'image/png' || this.imgList[i].type === 'image/jpeg';
                        if (!checkSuffix){
                            this.snackbar = true;
                            this.message = this.imgList[i].name + '不是图片格式';
                            return;
                        }
                        const fileSize = this.imgList[i].size / 1024 / 1024;
                        if (fileSize > 8){
                            this.snackbar = true;
                            this.message = this.imgList[i].name + '不得大于8M';
                            return
                        }
                    }
                }
                */

                this.loading = true;
                this.$http.post('apply/quickAdd', this.record).then(({data}) => {
                    if (data.code == 0){
                        this.applyId = data.applyId;
                        if (this.file){
                            let formData = new FormData();
                            formData.append('applyId', this.applyId);
                            formData.append('file', this.file);
                            const config = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            };
                            this.$http.post('file/upload', formData, config).then(({data}) => {
                                if (data.code == 0){
                                    this.snackbar = true;
                                    this.message = '上传文件成功！';
                                } else {
                                    this.snackbar = true;
                                    this.message = data.message;
                                }
                            }).catch((err) => {
                                this.snackbar = true;
                                this.message = err;
                                this.loading = false;
                            })
                        }
                        if (this.imgList.length > 0){
                            const config = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            };
                            for (let i = 0; i < this.imgList.length; i++){
                                let formData = new FormData();
                                formData.append('applyId', this.applyId);
                                formData.append('file', this.imgList[i]);
                                this.$http.post('file/upload', formData, config).then(({data}) => {
                                    if (data.code == 0){
                                        console.log(this.files[i].name + '上传成功！')
                                    } else {
                                        this.snackbar = true;
                                        this.message = data.message;
                                    }
                                }).catch((err) => {
                                    this.snackbar = true;
                                    this.message = err;
                                    this.loading = false;
                                })
                            }
                        }
                        this.loading = false;
                        this.success = true;
                    } else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch((err) => {
                    this.loading = false;
                    this.snackbar = true;
                    this.message = err;
                })
            }
        },
        created() {
            this.getProvinceList();
            this.getJobList();
            if(this.$route.query && this.$route.query.job){
                this.record.jobId = this.$route.query.job
            }
        },

        // 将对象格式化成字符串
        changeText() {
            let test = JSON.stringify(this.record.jobId) // JSON.stringify()用于将JavaScript对象或值转换为JSON字符串
            let test2 = test.slice(1, test.length - 1)
            let test3 = []
            for (let i = 0; i < test2.length; i++) {
                test3.push(test2[i].replace('"', ''))
            }
            for (let j = 0; j < test3.length; j++) {
                if (test3[j] === '') {
                    test3.splice(j, 1)
                }
            }
            this.tempObj = test3.join('')
        }

    }
</script>

<style scoped>
.upload-job-card{padding:10px;}
.upload-job-title{text-align: center;}
</style>
